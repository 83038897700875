import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();




// "scripts": {
//   // Starts the development server using the development environment
//   "start:dev": "env-cmd -f .env.development react-scripts start",

//   // Starts the app in production mode using the production environment
//   "start:prod": "env-cmd -f .env.production react-scripts start",

//   // Builds the app for production, creating an optimized static build
//   "build:prod": "env-cmd -f .env.production react-scripts build",

//   // Builds the app for development mode (not optimized, includes source maps)
//   "build:dev": "env-cmd -f .env.development react-scripts build",

//   // Runs the test suite in interactive watch mode
//   "test": "react-scripts test",

//   // Ejects the app configuration (irreversible action)
//   "eject": "react-scripts eject"
// }
// "start:prod": "npm run build && serve -s build",
// npm install serve --save-dev
// npm run build:prod
// npm run start:dev
