// followersSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Fetch followers
export const fetchFollowers = createAsyncThunk('followers/fetchFollowers', async (userId, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL_AUTH}/user/follower/${userId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data.data.Items || [];
  } catch (error) {
    return rejectWithValue('Error fetching followers');
  }
});

// Follow/Unfollow user
export const followOrUnfollow = createAsyncThunk('followers/followOrUnfollow', async ({ userId, isFollowing }, { rejectWithValue, dispatch }) => {
  const token = localStorage.getItem('token');
  const apiUrl = `${process.env.REACT_APP_BASE_URL_AUTH}/${isFollowing ? 'unfollow' : 'follow'}`;
  const method = isFollowing ? 'delete' : 'post';

  try {
    const response = await axios({
      method,
      url: apiUrl,
      data: { userId },
      headers: { Authorization: `Bearer ${token}` },
    });

    if (response.status === 201 || response.status === 200) {
      dispatch(fetchFollowers(userId)); // Refetch the followers after follow/unfollow
      return { userId, isFollowing };
    } else {
      throw new Error('Failed to follow/unfollow');
    }
  } catch (error) {
    return rejectWithValue('Error performing follow/unfollow');
  }
});

// Followers slice
const NewfollowersSlice = createSlice({
  name: 'newfollowers',
  initialState: {
    followers: [],
    loading: false,
    showUnfollowModal: false,
    selectedUser: null,
    buttonLoading: null, // Track loading state for specific button
    error: null,
  },
  reducers: {
    setShowUnfollowModal: (state, action) => {
      state.showUnfollowModal = action.payload;
    },
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Followers
      .addCase(fetchFollowers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFollowers.fulfilled, (state, action) => {
        state.loading = false;
        state.followers = action.payload;
      })
      .addCase(fetchFollowers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Follow/Unfollow User
      .addCase(followOrUnfollow.pending, (state, action) => {
        state.buttonLoading = action.meta.arg.userId;
      })
      .addCase(followOrUnfollow.fulfilled, (state) => {
        state.buttonLoading = null;
        state.showUnfollowModal = false;
      })
      .addCase(followOrUnfollow.rejected, (state, action) => {
        state.buttonLoading = null;
        state.error = action.payload;
      });
  },
});

export const { setShowUnfollowModal, setSelectedUser } = NewfollowersSlice.actions;

export default NewfollowersSlice.reducer;
