import React, { lazy, Suspense, useEffect, useState, memo } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import PrivateRoute from './components/PrivateCompoent/PrivateRoute';
import DefaultLayout from './components/layout/DefaultLayout';
import OfflineComponent from './OfflineComponent';
import useNetworkStatus from './useNetworkStatus';
import BloopersLoader from './components/SpinLoader/BloopersLoader';
import NotFound from './components/SpinLoader/NotFound';
import EditProfile from './pages/Setting/SettingBox/EditProfile';
import Search from './pages/Search/Search';
import ShareReelsVideo from './pages/reels/shareReels/ShareReelsVideo';
// Lazily load components to reduce initial bundle size
const Register = lazy(() => import('./pages/Auth/Register/Register/Register'));
const OtpVerifiedFirebase = lazy(() => import('./pages/Auth/Register/OtpVerification/OtpVerifiedFirebase'));
const ResOtpVerifiedEmail = lazy(() => import('./pages/Auth/Register/OtpVerification/OtpVerifiedEmail'));
const UserName = lazy(() => import('./pages/Auth/Register/UserNameOrPassword/UserName'));
const Reels = lazy(() => import('./pages/reels/Reels'));
const Tab = lazy(() => import('./pages/notification/Tab'));
const Profile = lazy(() => import('./pages/profile/CurrentUser/Profile'));
const Explore = lazy(() => import('./pages/explore/Explore'));
const SettingEdit = lazy(() => import('./pages/Setting/SettingEdit'));
const SettingChangePassword = lazy(() => import('./pages/Setting/SettingChangePassword'));
const ProfilePage = lazy(() => import('./pages/profile/newUser/ProfilePage'));
const Hashtag = lazy(() => import('./pages/hashtags/Hashtag'));
const Login = lazy(() => import('./pages/Auth/Login/Login'));
const ForgotPassword = lazy(() => import('./pages/Auth/ForgotPassword/ForgotPassword/ForgotPassword'));
const OtpVerifiedEmail = lazy(() => import('./pages/Auth/ForgotPassword/OtpVerification/OtpVerifiedEmail'));
const OtpVerifiedfirebse = lazy(() => import('./pages/Auth/ForgotPassword/OtpVerification/OtpVerifiedfirebse'));
const ResetPassword = lazy(() => import('./pages/Auth/ForgotPassword/ResetPassword/ResetPassword'));
const DobUserName = lazy(() => import('./pages/Auth/Register/DobUserName/DobUserName'));

const App = memo(() => {
  const { isOnline } = useNetworkStatus();  // Hook to detect network status
  const [loading, setLoading] = useState(true);
  const { pathname } = useLocation();

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => setLoading(false), 1000); // Simulate loading delay
    return () => clearTimeout(timer);  // Cleanup timer
  }, [pathname]);

  // Offline component for handling no network scenario
  if (!isOnline) {
    return <OfflineComponent />;
  }

  // Loading screen during navigation
  if (loading) {
    return <BloopersLoader />;
  }
  return (
    <Suspense fallback={<BloopersLoader />}>
      <Routes>
        {/* Public routes */}
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/verify-email" element={<ResOtpVerifiedEmail />} />
        <Route path="/verify-phone" element={<OtpVerifiedFirebase />} />
        <Route path="/password-name" element={<UserName />} />
        <Route path="/dob-username" element={<DobUserName />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/email-otp" element={<OtpVerifiedEmail />} />
        <Route path="/firebase-otp" element={<OtpVerifiedfirebse />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        
        {/* Private routes */}
        <Route element={<PrivateRoute />}>
          <Route element={<DefaultLayout />}>
            <Route path="/reels" element={<Reels />} />
            <Route exact path="/reels/:id" element={<ShareReelsVideo/>}/>
            <Route path="/search" element={<Search />} />
            <Route path="/explore" element={<Explore />} />
            <Route path="/notification" element={<Tab />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/user/profile/:userId" element={<ProfilePage />} />        
            <Route path="/hashtag/:hashtag" element={<Hashtag />} />
            <Route path="/setting" element={<EditProfile />} />
            <Route path="/setting/edit" element={<SettingEdit />} />
            <Route path="/setting/change-password" element={<SettingChangePassword />} />
          </Route>
        </Route>
        {/* Catch-all route for 404 - Not Found */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
});

export default App;











