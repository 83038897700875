// NewfollowingSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-hot-toast';

// Thunk to fetch following users
export const fetchFollowing = createAsyncThunk(
  'following/fetchFollowing',
  async (userId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_AUTH}/user/following/${userId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data.data.Items || [];
    } catch (error) {
      toast.error('Failed to fetch following users.');
      return rejectWithValue(error.response.data.message);
    }
  }
);

// Thunk to follow/unfollow a user
export const followOrUnfollow = createAsyncThunk(
  'following/followOrUnfollow',
  async ({ userId, isFollowing }, { rejectWithValue, dispatch }) => {
    try {
      const token = localStorage.getItem('token');
      const method = isFollowing ? 'delete' : 'post';
      const response = await axios({
        method,
        url: `${process.env.REACT_APP_BASE_URL_AUTH}/${isFollowing ? 'unfollow' : 'follow'}`,
        data: { userId },
        headers: { Authorization: `Bearer ${token}` },
      });

      // Refetch following users after successful follow/unfollow
      dispatch(fetchFollowing(userId));
      return response.data;
    } catch (error) {
      toast.error('Failed to perform follow/unfollow action.');
      return rejectWithValue(error.response.data.message);
    }
  }
);

const NewfollowingSlice = createSlice({
  name: 'newfollowing',
  initialState: {
    following: [],
    loading: false,
    unfollowModal: false,
    selectedUser: null,
    buttonLoading: {}, // Track loading state for each button
    error: null,
  },
  reducers: {
    setSelectedUser(state, action) {
      state.selectedUser = action.payload;
      state.unfollowModal = true;
    },
    setUnfollowModal(state, action) {
      state.unfollowModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch following users
      .addCase(fetchFollowing.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFollowing.fulfilled, (state, action) => {
        state.following = action.payload;
        state.loading = false;
      })
      .addCase(fetchFollowing.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Follow/Unfollow user
      .addCase(followOrUnfollow.pending, (state, action) => {
        state.buttonLoading[action.meta.arg.userId] = true;
      })
      .addCase(followOrUnfollow.fulfilled, (state, action) => {
        state.buttonLoading[action.meta.arg.userId] = false;
      })
      .addCase(followOrUnfollow.rejected, (state, action) => {
        state.buttonLoading[action.meta.arg.userId] = false;
        state.error = action.payload;
      });
  },
});

export const { setSelectedUser, setUnfollowModal } = NewfollowingSlice.actions;

export default NewfollowingSlice.reducer;





