// followersSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk for fetching followers
export const fetchFollowers = createAsyncThunk('followers/fetchFollowers', async (_, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) throw new Error('Authentication token not found.');

    const response = await axios.get(`${process.env.REACT_APP_BASE_URL_AUTH}/followers`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      return response.data.data.Items || [];
    } else {
      throw new Error(response.data.message || 'Failed to fetch followers');
    }
  } catch (error) {
    return rejectWithValue(error.message || 'Something went wrong.');
  }
});

// Async thunk for following/unfollowing a user
export const followOrUnfollow = createAsyncThunk(
  'followers/followOrUnfollow',
  async ({ userId, isFollowing }, { rejectWithValue, dispatch }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('Authentication token not found.');

      const apiUrl = `${process.env.REACT_APP_BASE_URL_AUTH}/${isFollowing ? 'unfollow' : 'follow'}`;
      const method = isFollowing ? 'delete' : 'post';

      const response = await axios({
        method,
        url: apiUrl,
        data: { userId },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 201 || response.status === 200) {
        // Refetch followers after success
        dispatch(fetchFollowers());
        return userId; // Return userId to remove from modal or list if necessary
      } else {
        throw new Error(response.data.message || 'Failed to follow/unfollow');
      }
    } catch (error) {
      return rejectWithValue(error.message || 'Something went wrong.');
    }
  }
);

const followersSlice = createSlice({
  name: 'followers',
  initialState: {
    followers: [],
    loading: false,
    unfollowModal: false,
    selectedUser: null,
    loadingFollow: {},
    error: null,
  },
  reducers: {
    setUnfollowModal: (state, action) => {
      state.unfollowModal = action.payload.status;
      state.selectedUser = action.payload.user || null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch followers
      .addCase(fetchFollowers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFollowers.fulfilled, (state, action) => {
        state.loading = false;
        state.followers = action.payload;
      })
      .addCase(fetchFollowers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Follow or unfollow user
      .addCase(followOrUnfollow.pending, (state, action) => {
        state.loadingFollow[action.meta.arg.userId] = true;
      })
      .addCase(followOrUnfollow.fulfilled, (state, action) => {
        state.loadingFollow[action.meta.arg.userId] = false;
        state.unfollowModal = false; // Close the unfollow modal after success
      })
      .addCase(followOrUnfollow.rejected, (state, action) => {
        state.loadingFollow[action.meta.arg.userId] = false;
        state.error = action.payload;
      });
  },
});

export const { setUnfollowModal } = followersSlice.actions;

export default followersSlice.reducer;
