import React from 'react';
import { Outlet } from 'react-router-dom';
import Home from '../MainHeader/Home';

const DefaultLayout = () => {
  return (
    <div className="flex flex-col md:flex-row">
      {/* Sidebar for larger screens */}
      <Home />
      {/* Main content area */}
      <main className="flex-grow bg-black text-white sm:m-0 md:ml-20 lg:ml-64">
        <div className="px-4  md:px-6 lg:px-10 sm:p-0">
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default DefaultLayout;













