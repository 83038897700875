// notificationsSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken, onMessage } from 'firebase/messaging';
import { messaging } from '../../../../firebase/config';

// Async thunk to fetch notifications from the API
export const fetchNotifications = createAsyncThunk('notifications/fetchNotifications', async (_, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) throw new Error('No token found in localStorage');

    const response = await axios.get(`${process.env.REACT_APP_BASE_URL_AUTH}/notification`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data?.data?.Items || [];
  } catch (error) {
    return rejectWithValue('Error fetching notifications');
  }
});

// Async thunk to request notification permission
export const requestNotificationPermission = createAsyncThunk('notifications/requestPermission', async (_, { rejectWithValue }) => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
      });

      // Optionally, send the token to your server
      console.log('Notification Token Generated:', token);
      return token;
    } else {
      throw new Error('Notification permission denied');
    }
  } catch (error) {
    return rejectWithValue('Error requesting notification permission');
  }
});

// Notifications slice
const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    notifications: [],
    loading: false,
    error: null,
    permissionGranted: false,
  },
  reducers: {
    // Add notifications via Firebase onMessage
    addNotification: (state, action) => {
      state.notifications.unshift(action.payload); // Add new notification to the beginning of the list
    },
    clearNotifications: (state) => {
      state.notifications = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchNotifications thunk
      .addCase(fetchNotifications.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.notifications = action.payload;
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle requestNotificationPermission thunk
      .addCase(requestNotificationPermission.fulfilled, (state) => {
        state.permissionGranted = true;
      })
      .addCase(requestNotificationPermission.rejected, (state, action) => {
        state.error = action.payload;
        state.permissionGranted = false;
      });
  },
});

export const { addNotification, clearNotifications } = notificationsSlice.actions;

export default notificationsSlice.reducer;
