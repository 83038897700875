// // exploreSlice.js
// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import axios from 'axios';
// // import { showToast } from '../profile/newUser/showToast';

// const PAGE_SIZE = 19;

// // Thunk to fetch videos
// export const fetchVideos = createAsyncThunk('explore/fetchVideos', async (lastEvaluatedKey = null, { getState, rejectWithValue }) => {
//   try {
//     const token = localStorage.getItem('token');
//     const params = { pageSize: PAGE_SIZE };

//     if (lastEvaluatedKey) {
//       params.id = lastEvaluatedKey.id;
//       params.userId = lastEvaluatedKey.userId;
//       params.createdAt = lastEvaluatedKey.createdAt;
//     }

//     const response = await axios.get(`${process.env.REACT_APP_BASE_URL_VIDEO}/videos`, {
//       headers: { Authorization: `Bearer ${token}` },
//       params,
//     });

//     return response.data.data;
//   } catch (error) {
//     // showToast('error', 'Failed to fetch videos.');
//     return rejectWithValue('Error fetching videos');
//   }
// });

// // Thunk to search for videos, users, and hashtags
// export const searchExplore = createAsyncThunk('explore/searchExplore', async (searchQuery, { rejectWithValue }) => {
//   try {
//     const token = localStorage.getItem('token');
//     const response = await axios.get(`${process.env.REACT_APP_BASE_URL_GENERAL}/discovery`, {
//       headers: { Authorization: `Bearer ${token}` },
//       params: { search: searchQuery, pageSize: PAGE_SIZE, pageNumber: 1 },
//     });

//     return response.data.data;
//   } catch (error) {
//     // showToast('error', 'Failed to perform search.');
//     return rejectWithValue('Error performing search');
//   }
// });

// // Thunk to follow/unfollow users
// export const followOrUnfollowUser = createAsyncThunk('explore/followOrUnfollowUser', async ({ userId, isFollowing }, { rejectWithValue }) => {
//   try {
//     const token = localStorage.getItem('token');
//     const apiUrl = `${process.env.REACT_APP_BASE_URL_AUTH}/${isFollowing ? 'unfollow' : 'follow'}`;
//     const method = isFollowing ? 'delete' : 'post';

//     const response = await axios({
//       method,
//       url: apiUrl,
//       data: { userId },
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });

//     if (response.status === 201 || response.status === 200) {
//       return { userId, isFollowing: !isFollowing }; // Toggle isFollowing
//     } else {
//       throw new Error('Something went wrong');
//     }
//   } catch (error) {
//     return rejectWithValue('Error following/unfollowing user');
//   }
// });

// // Initial state
// const initialState = {
//   videos: [],
//   searchVideos: [],
//   users: [],
//   hashtags: [],
//   hasMore: true,
//   loading: true,
//   loadingMore: false,
//   loadingVideos: true,
//   loadingUsers: true,
//   loadingHashtags: true,
//   lastEvaluatedKey: null,
//   inputValue: '',
//   searchQuery: '',
//   showSearchResults: false,
//   activeTab: 'all',
//   selectedPost: null,
//   selectedIndex: null,
//   isFollowing: false,
// };

// // Slice definition
// const exploreSlice = createSlice({
//   name: 'explore',
//   initialState,
//   reducers: {
//     setActiveTab: (state, action) => {
//       state.activeTab = action.payload;
//     },
//     setSelectedPost: (state, action) => {
//       state.selectedPost = action.payload.post;
//       state.selectedIndex = action.payload.index;
//     },
//     clearSearch: (state) => {
//       state.searchQuery = '';
//       state.inputValue = '';
//       state.showSearchResults = false;
//     },
//     setInputValue: (state, action) => {
//       state.inputValue = action.payload;
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(fetchVideos.pending, (state) => {
//         state.loadingMore = true;
//       })
//       .addCase(fetchVideos.fulfilled, (state, action) => {
//         const { Items, LastEvaluatedKey } = action.payload;
//         state.videos = [...state.videos, ...Items];
//         state.lastEvaluatedKey = LastEvaluatedKey;
//         state.hasMore = Items.length > 0;
//         state.loadingMore = false;
//       })
//       .addCase(fetchVideos.rejected, (state) => {
//         state.loadingMore = false;
//       })
//       .addCase(searchExplore.pending, (state) => {
//         state.loading = true;
//         state.loadingVideos = true;
//         state.loadingUsers = true;
//         state.loadingHashtags = true;
//       })
//       .addCase(searchExplore.fulfilled, (state, action) => {
//         const { videos, users, hashTags } = action.payload;
//         state.searchVideos = videos;
//         state.users = users;
//         state.hashtags = hashTags;
//         state.loading = false;
//         state.loadingVideos = false;
//         state.loadingUsers = false;
//         state.loadingHashtags = false;
//         state.showSearchResults = true;
//       })
//       .addCase(searchExplore.rejected, (state) => {
//         state.loading = false;
//         state.loadingVideos = false;
//         state.loadingUsers = false;
//         state.loadingHashtags = false;
//       })
//       .addCase(followOrUnfollowUser.fulfilled, (state, action) => {
//         const { userId, isFollowing } = action.payload;
//         const userIndex = state.users.findIndex((user) => user.userId === userId);
//         if (userIndex !== -1) {
//           state.users[userIndex].isFollowing = isFollowing;
//         }
//         state.isFollowing = isFollowing;
//       });
//   },
// });

// export const { setActiveTab, setSelectedPost, clearSearch, setInputValue } = exploreSlice.actions;

// export default exploreSlice.reducer;






// exploreSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
// import { showToast } from '../profile/newUser/showToast';

const PAGE_SIZE = 19;

// Thunk to fetch videos
export const fetchVideos = createAsyncThunk('explore/fetchVideos', async (lastEvaluatedKey = null, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token');
    const params = { pageSize: PAGE_SIZE };

    if (lastEvaluatedKey) {
      params.id = lastEvaluatedKey.id;
      params.userId = lastEvaluatedKey.userId;
      params.createdAt = lastEvaluatedKey.createdAt;
    }

    const response = await axios.get(`${process.env.REACT_APP_BASE_URL_VIDEO}/videos`, {
      headers: { Authorization: `Bearer ${token}` },
      params,
    });

    return response.data.data;
  } catch (error) {
    // showToast('error', 'Failed to fetch videos.');
    return rejectWithValue('Error fetching videos');
  }
});

// Thunk to search for videos, users, and hashtags
export const searchExplore = createAsyncThunk('explore/searchExplore', async (searchQuery, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL_GENERAL}/discovery`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { search: searchQuery, pageSize: PAGE_SIZE, pageNumber: 1 },
    });

    return response.data.data;
  } catch (error) {
    // showToast('error', 'Failed to perform search.');
    return rejectWithValue('Error performing search');
  }
});

// Thunk to follow/unfollow users
export const followOrUnfollowUser = createAsyncThunk(
  'explore/followOrUnfollowUser',
  async ({ userId, isFollowing }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const apiUrl = `${process.env.REACT_APP_BASE_URL_AUTH}/${isFollowing ? 'unfollow' : 'follow'}`;
      const method = isFollowing ? 'delete' : 'post';

      const response = await axios({
        method,
        url: apiUrl,
        data: { userId },
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 201 || response.status === 200) {
        return { userId, isFollowing: !isFollowing }; // Toggle isFollowing
      } else {
        throw new Error('Something went wrong');
      }
    } catch (error) {
      return rejectWithValue('Error following/unfollowing user');
    }
  }
);

const initialState = {
  videos: [],
  searchVideos: [],
  users: [],
  hashtags: [],
  hasMore: true,
  loading: true,
  loadingMore: false,
  loadingVideos: true,
  loadingUsers: true,
  loadingHashtags: true,
  lastEvaluatedKey: null,
  inputValue: '',
  searchQuery: '',
  showSearchResults: false, // Search results are hidden by default
  activeTab: 'all',
  selectedPost: null,
  selectedIndex: null,
  isFollowing: false,
};

const exploreSlice = createSlice({
  name: 'explore',
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setSelectedPost: (state, action) => {
      state.selectedPost = action.payload.post;
      state.selectedIndex = action.payload.index;
    },
    clearSearch: (state) => {
      state.searchQuery = '';
      state.inputValue = '';
      state.showSearchResults = false; // Hide search results when clearing search
    },
    setInputValue: (state, action) => {
      state.inputValue = action.payload;
    },
    setShowSearchResults: (state, action) => {
      state.showSearchResults = action.payload; // Control when to show search results
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVideos.pending, (state) => {
        state.loadingMore = true;
      })
      .addCase(fetchVideos.fulfilled, (state, action) => {
        const { Items, LastEvaluatedKey } = action.payload;
        state.videos = [...state.videos, ...Items];
        state.lastEvaluatedKey = LastEvaluatedKey;
        state.hasMore = Items.length > 0;
        state.loadingMore = false;
      })
      .addCase(fetchVideos.rejected, (state) => {
        state.loadingMore = false;
      })
      .addCase(searchExplore.pending, (state) => {
        state.loading = true;
        state.loadingVideos = true;
        state.loadingUsers = true;
        state.loadingHashtags = true;
      })
      .addCase(searchExplore.fulfilled, (state, action) => {
        const { videos, users, hashTags } = action.payload;
        state.searchVideos = videos;
        state.users = users;
        state.hashtags = hashTags;
        state.loading = false;
        state.loadingVideos = false;
        state.loadingUsers = false;
        state.loadingHashtags = false;
        state.showSearchResults = true; // Show search results when search is fulfilled
      })
      .addCase(searchExplore.rejected, (state) => {
        state.loading = false;
        state.loadingVideos = false;
        state.loadingUsers = false;
        state.loadingHashtags = false;
        state.showSearchResults = false; // Hide search results on error
      })
      .addCase(followOrUnfollowUser.fulfilled, (state, action) => {
        const { userId, isFollowing } = action.payload;
        const userIndex = state.users.findIndex((user) => user.userId === userId);
        if (userIndex !== -1) {
          state.users[userIndex].isFollowing = isFollowing;
        }
        state.isFollowing = isFollowing;
      });
  },
});

export const { setActiveTab, setSelectedPost, clearSearch, setInputValue, setShowSearchResults } = exploreSlice.actions;

export default exploreSlice.reducer;
