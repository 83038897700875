import { configureStore } from '@reduxjs/toolkit';
import searchReducer from './components/redux/slices/searchSlice'; // updated relative path
import notificationsReducer from './components/redux/slices/notification/notificationsSlice'; 
import followingsReducer from './components/redux/slices/notification/followingsSlice'; 
import followersReducer from './components/redux/slices/notification/followersSlice'; 
import exploreReducer from './components/redux/slices/explore/exploreSlice'; 
import profileReducer from './components/redux/slices/profile/newUser/profileSlice'; 
import NewfollowerReducer from './components/redux/slices/profile/newUser/modal/NewfollowersSlice'; 
import NewfollowingReducer from './components/redux/slices/profile/newUser/modal/NewfollowingSlice'; 
import newUservideoModalReducer from './components/redux/slices/profile/newUser/modal/videoModalSlice'; 

const store = configureStore({
  reducer: {
    search: searchReducer,
    notifications: notificationsReducer,
    followings: followingsReducer,
    followers: followersReducer, 
    explore: exploreReducer,
    profile: profileReducer,
    newfollowers:NewfollowerReducer,
    newfollowing:NewfollowingReducer,
    newuservideoModal:newUservideoModalReducer

    
  },
});

export default store;
