// followingsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk for fetching followings
export const fetchFollowings = createAsyncThunk('followings/fetchFollowings', async (_, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) throw new Error('User is not authenticated.');

    const response = await axios.get(`${process.env.REACT_APP_BASE_URL_AUTH}/followings`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      return response.data.data.Items || [];
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    return rejectWithValue(error.message || 'Something went wrong');
  }
});

// Async thunk for unfollowing a user
export const unfollowUser = createAsyncThunk('followings/unfollowUser', async (userId, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) throw new Error('User is not authenticated.');

    const apiUrl = `${process.env.REACT_APP_BASE_URL_AUTH}/unfollow`;
    const response = await axios.delete(apiUrl, {
      data: { userId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 201 || response.status === 200) {
      return userId; // Return the userId to remove it from the list
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    return rejectWithValue(error.message || 'Something went wrong');
  }
});

// Followings slice
const followingsSlice = createSlice({
  name: 'followings',
  initialState: {
    following: [],
    loading: false,
    unfollowModal: false,
    selectedUser: null,
    isUnfollowing: false,
    error: null,
  },
  reducers: {
    setUnfollowModal: (state, action) => {
      state.unfollowModal = action.payload.status;
      state.selectedUser = action.payload.user || null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch followings
      .addCase(fetchFollowings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFollowings.fulfilled, (state, action) => {
        state.loading = false;
        state.following = action.payload;
      })
      .addCase(fetchFollowings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Unfollow user
      .addCase(unfollowUser.pending, (state) => {
        state.isUnfollowing = true;
        state.error = null;
      })
      .addCase(unfollowUser.fulfilled, (state, action) => {
        state.isUnfollowing = false;
        state.following = state.following.filter((user) => user.userId !== action.payload);
        state.unfollowModal = false; // Close modal on success
      })
      .addCase(unfollowUser.rejected, (state, action) => {
        state.isUnfollowing = false;
        state.error = action.payload;
      });
  },
});

export const { setUnfollowModal } = followingsSlice.actions;

export default followingsSlice.reducer;
