// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import axios from 'axios';
// import { encode, decode } from 'utf8';

// // Fetch comments from API
// export const fetchComments = createAsyncThunk(
//   'videoModal/fetchComments',
//   async (videoId, { rejectWithValue }) => {
//     try {
//       const token = localStorage.getItem('token');
//       const response = await axios.get(`${process.env.REACT_APP_BASE_URL_VIDEO}/comment`, {
//         params: { videoId },
//         headers: { Authorization: `Bearer ${token}` },
//       });
//       return response.data.data.Items.map(comment => ({
//         ...comment,
//         comment: decode(comment.comment),
//       }));
//     } catch (error) {
//       return rejectWithValue(error.response?.data?.message || 'Error fetching comments');
//     }
//   }
// );

// // Post a new comment to the API
// export const submitComment = createAsyncThunk(
//   'videoModal/submitComment',
//   async ({ videoId, userId, comment }, { rejectWithValue, dispatch }) => {
//     try {
//       const token = localStorage.getItem('token');
//       const encodedComment = encode(comment);
//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL_VIDEO}/comment`,
//         { videoId, videoUserId: userId, comment: encodedComment },
//         { headers: { Authorization: `Bearer ${token}` } }
//       );
//       dispatch(fetchComments(videoId)); // Refetch comments after successful submission
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response?.data?.message || 'Error posting comment');
//     }
//   }
// );

// // Like/Unlike a video
// export const toggleLike = createAsyncThunk(
//   'videoModal/toggleLike',
//   async ({ videoId, userId, liked }, { rejectWithValue }) => {
//     try {
//       const token = localStorage.getItem('token');
//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL_VIDEO}/updateVideo`,
//         { videoUserId: userId, videoId, like: liked ? 0 : 1 },
//         { headers: { Authorization: `Bearer ${token}` } }
//       );
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response?.data?.message || 'Error toggling like');
//     }
//   }
// );

// // Like/Unlike a comment
// export const toggleCommentLike = createAsyncThunk(
//   'videoModal/toggleCommentLike',
//   async ({ videoId, commentId, liked }, { rejectWithValue, dispatch }) => {
//     try {
//       const token = localStorage.getItem('token');
//       const response = await axios.patch(
//         `${process.env.REACT_APP_BASE_URL_VIDEO}/comment`,
//         { videoId, commentId, like: liked ? 2 : 1 },
//         { headers: { Authorization: `Bearer ${token}` } }

        
//       );
//       dispatch(fetchComments(videoId)); // Refetch comments after like toggle
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response?.data?.message || 'Error toggling comment like');
//     }
//   }
// );

// // Video modal slice
// const newUservideoModalSlice = createSlice({
//   name: 'newuservideoModal',
//   initialState: {
//     comments: [],
//     loading: false,
//     error: null,
//     liked: false,
//     likeCount: 0,
//     downloadCount: 0,
//     shareCount: 0,
//     showEmojiPicker: false,
//     newComment: '',
//   },
//   reducers: {
//     setShowEmojiPicker(state, action) {
//       state.showEmojiPicker = action.payload;
//     },
//     setNewComment(state, action) {
//       state.newComment = action.payload;
//     },
//     incrementDownload(state) {
//       state.downloadCount += 1;
//     },
//     incrementShare(state) {
//       state.shareCount += 1;
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(fetchComments.pending, (state) => {
//         state.loading = true;
//       })
//       .addCase(fetchComments.fulfilled, (state, action) => {
//         state.comments = action.payload;
//         state.loading = false;
//       })
//       .addCase(fetchComments.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.payload;
//       })
//       .addCase(submitComment.pending, (state) => {
//         state.loading = true;
//       })
//       .addCase(submitComment.fulfilled, (state) => {
//         state.newComment = '';
//         state.loading = false;
//       })
//       .addCase(submitComment.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.payload;
//       })
//       .addCase(toggleLike.pending, (state) => {
//         state.loading = true;
//       })
//       .addCase(toggleLike.fulfilled, (state) => {
//         state.liked = !state.liked;
//         state.loading = false;
//       })
//       .addCase(toggleLike.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.payload;
//       })
//       .addCase(toggleCommentLike.pending, (state) => {
//         state.loading = true;
//       })
//       .addCase(toggleCommentLike.fulfilled, (state) => {
//         state.loading = false;
//       })
//       .addCase(toggleCommentLike.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.payload;
//       });
//   },
// });

// export const { setShowEmojiPicker, setNewComment, incrementDownload, incrementShare } = newUservideoModalSlice.actions;

// export default newUservideoModalSlice.reducer;














// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import axios from 'axios';
// import { encode, decode } from 'utf8';

// // Thunk to fetch comments for a video
// export const fetchComments = createAsyncThunk(
//   'videoModal/fetchComments',
//   async (videoId, { rejectWithValue }) => {
//     try {
//       const token = localStorage.getItem('token');
//       const response = await axios.get(`${process.env.REACT_APP_BASE_URL_VIDEO}/comment`, {
//         params: { videoId },
//         headers: { Authorization: `Bearer ${token}` },
//       });
//       const comments = response.data.data.Items.map(comment => ({
//         ...comment,
//         comment: decode(comment.comment),
//       }));
//       return comments;
//     } catch (error) {
//       return rejectWithValue(error.response?.data?.message || 'Error fetching comments');
//     }
//   }
// );

// // Thunk to submit a new comment
// export const submitComment = createAsyncThunk(
//   'videoModal/submitComment',
//   async ({ videoId, userId, comment }, { rejectWithValue, dispatch }) => {
//     try {
//       const token = localStorage.getItem('token');
//       const encodedComment = encode(comment);
//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL_VIDEO}/comment`,
//         { videoId, videoUserId: userId, comment: encodedComment },
//         { headers: { Authorization: `Bearer ${token}` } }
//       );
//       dispatch(fetchComments(videoId)); // Refetch comments after submission
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response?.data?.message || 'Error submitting comment');
//     }
//   }
// );

// // Thunk to like/unlike a video
// export const toggleLike = createAsyncThunk(
//   'videoModal/toggleLike',
//   async ({ videoId, userId, liked }, { rejectWithValue }) => {
//     try {
//       const token = localStorage.getItem('token');
//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL_VIDEO}/updateVideo`,
//         { videoUserId: userId, videoId, like: liked ? 0 : 1 },
//         { headers: { Authorization: `Bearer ${token}` } }
//       );
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response?.data?.message || 'Error toggling like');
//     }
//   }
// );

// // Thunk to like/unlike a comment
// export const toggleCommentLike = createAsyncThunk(
//   'videoModal/toggleCommentLike',
//   async ({ videoId, commentId, liked }, { rejectWithValue, dispatch }) => {
//     try {
//       const token = localStorage.getItem('token');
//       const response = await axios.patch(
//         `${process.env.REACT_APP_BASE_URL_VIDEO}/comment`,
//         { videoId, commentId, like: liked ? 2 : 1 },
//         { headers: { Authorization: `Bearer ${token}` } }
//       );
//       dispatch(fetchComments(videoId)); // Refetch comments after toggling comment like
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response?.data?.message || 'Error toggling comment like');
//     }
//   }
// );

// // Thunk to download a video
// export const downloadVideo = createAsyncThunk(
//   'videoModal/downloadVideo',
//   async ({ videoId, userId, videoUrl }, { rejectWithValue, dispatch }) => {
//     try {
//       const token = localStorage.getItem('token');
//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL_VIDEO}/updateVideo`,
//         { videoUserId: userId, videoId, downloads: 1 },
//         { headers: { Authorization: `Bearer ${token}` } }
//       );
//       const link = document.createElement('a');
//       link.href = videoUrl;
//       link.setAttribute('download', 'video.mp4');
//       document.body.appendChild(link);
//       link.click();
//       link.remove();
//       dispatch(incrementDownload());
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response?.data?.message || 'Error downloading video');
//     }
//   }
// );

// // Thunk to share a video
// export const shareVideo = createAsyncThunk(
//   'videoModal/shareVideo',
//   async ({ videoId, userId }, { rejectWithValue, dispatch }) => {
//     try {
//       const token = localStorage.getItem('token');
//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL_VIDEO}/updateVideo`,
//         { videoUserId: userId, videoId, share: 1 },
//         { headers: { Authorization: `Bearer ${token}` } }
//       );
//       dispatch(incrementShare());
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response?.data?.message || 'Error sharing video');
//     }
//   }
// );

// // Slice for video modal state management
// const newUservideoModalSlice = createSlice({
//   name: 'newuservideoModal',
//   initialState: {
//     comments: [],
//     loading: false,
//     error: null,
//     liked: false,
//     likeCount: 0,
//     downloadCount: 0,
//     shareCount: 0,
//     showEmojiPicker: false,
//     newComment: '',
//   },
//   reducers: {
//     setShowEmojiPicker(state, action) {
//       state.showEmojiPicker = action.payload;
//     },
//     setNewComment(state, action) {
//       state.newComment = action.payload;
//     },
//     incrementDownload(state) {
//       state.downloadCount += 1;
//     },
//     incrementShare(state) {
//       state.shareCount += 1;
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(fetchComments.pending, (state) => {
//         state.loading = true;
//       })
//       .addCase(fetchComments.fulfilled, (state, action) => {
//         state.comments = action.payload;
//         state.loading = false;
//       })
//       .addCase(fetchComments.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.payload;
//       })
//       .addCase(submitComment.pending, (state) => {
//         state.loading = true;
//       })
//       .addCase(submitComment.fulfilled, (state) => {
//         state.newComment = '';
//         state.loading = false;
//       })
//       .addCase(submitComment.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.payload;
//       })
//       .addCase(toggleLike.pending, (state) => {
//         state.loading = true;
//       })
//       .addCase(toggleLike.fulfilled, (state) => {
//         state.liked = !state.liked;
//         state.likeCount += state.liked ? 1 : -1;
//         state.loading = false;
//       })
//       .addCase(toggleLike.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.payload;
//       })
//       .addCase(toggleCommentLike.pending, (state) => {
//         state.loading = true;
//       })
//       .addCase(toggleCommentLike.fulfilled, (state) => {
//         state.loading = false;
//       })
//       .addCase(toggleCommentLike.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.payload;
//       })
//       .addCase(downloadVideo.pending, (state) => {
//         state.loading = true;
//       })
//       .addCase(downloadVideo.fulfilled, (state) => {
//         state.loading = false;
//       })
//       .addCase(downloadVideo.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.payload;
//       })
//       .addCase(shareVideo.pending, (state) => {
//         state.loading = true;
//       })
//       .addCase(shareVideo.fulfilled, (state) => {
//         state.loading = false;
//       })
//       .addCase(shareVideo.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.payload;
//       });
//   },
// });

// // Export the actions and the reducer
// export const { setShowEmojiPicker, setNewComment, incrementDownload, incrementShare } = newUservideoModalSlice.actions;
// export default newUservideoModalSlice.reducer;






import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { encode, decode } from 'utf8';

// API base URL
const BASE_URL = process.env.REACT_APP_BASE_URL_VIDEO;

// Helper function to get token from localStorage
const getToken = () => localStorage.getItem('token');

// Thunk to fetch comments for a video
export const fetchComments = createAsyncThunk(
  'videoModal/fetchComments',
  async (videoId, { rejectWithValue }) => {
    try {
      const token = getToken();
      const response = await axios.get(`${BASE_URL}/comment`, {
        params: { videoId },
        headers: { Authorization: `Bearer ${token}` },
      });
      const comments = response.data.data.Items.map(comment => ({
        ...comment,
        comment: decode(comment.comment),
      }));
      return comments;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Error fetching comments');
    }
  }
);

// Thunk to submit a new comment
export const submitComment = createAsyncThunk(
  'videoModal/submitComment',
  async ({ videoId, userId, comment }, { rejectWithValue, dispatch }) => {
    try {
      const token = getToken();
      const encodedComment = encode(comment);
      const response = await axios.post(
        `${BASE_URL}/comment`,
        { videoId, videoUserId: userId, comment: encodedComment },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      dispatch(fetchComments(videoId)); // Refetch comments after submission
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Error submitting comment');
    }
  }
);

// Thunk to like/unlike a video
export const toggleLike = createAsyncThunk(
  'videoModal/toggleLike',
  async ({ videoId, userId, liked }, { rejectWithValue }) => {
    try {
      const token = getToken();
      const response = await axios.post(
        `${BASE_URL}/updateVideo`,
        { videoUserId: userId, videoId, like: liked ? 0 : 1 }, // 0 to unlike, 1 to like
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Error toggling like');
    }
  }
);

// Thunk to like/unlike a comment
export const toggleCommentLike = createAsyncThunk(
  'comments/toggleCommentLike',
  async ({ videoId, commentId, liked }, { rejectWithValue,dispatch }) => {
    try {
      const token = getToken();
      const response = await axios.patch(
        `${BASE_URL}/comment`,
        { videoId, commentId, like: liked ? 2 : 1 }, // 1 to like, 2 to unlike
        { headers: { Authorization: `Bearer ${token}` } }
      );  
      // dispatch(fetchComments(videoId))
      return { commentId, liked: !liked }; // Toggle the liked state
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Error toggling comment like');
    }
  }
);

// Thunk to download a video
export const downloadVideo = createAsyncThunk(
  'videoModal/downloadVideo',
  async ({ videoId, userId, videoUrl }, { rejectWithValue, dispatch }) => {
    try {
      const token = getToken();
      await axios.post(
        `${BASE_URL}/updateVideo`,
        { videoUserId: userId, videoId, downloads: 1 },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const link = document.createElement('a');
      link.href = videoUrl;
      link.setAttribute('download', 'video.mp4');
      document.body.appendChild(link);
      link.click();
      link.remove();
      dispatch(incrementDownload()); // Increment download count in state
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Error downloading video');
    }
  }
);

// Thunk to share a video
export const shareVideo = createAsyncThunk(
  'videoModal/shareVideo',
  async ({ videoId, userId }, { rejectWithValue, dispatch }) => {
    try {
      const token = getToken();
      await axios.post(
        `${BASE_URL}/updateVideo`,
        { videoUserId: userId, videoId, share: 1 },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      dispatch(incrementShare()); // Increment share count in state
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Error sharing video');
    }
  }
);

// Slice for video modal state management
const newUserVideoModalSlice = createSlice({
  name: 'newuservideoModal',
  initialState: {
    comments: [],
    loading: false,
    error: null,
    liked: false,
    likeCount: 0,
    downloadCount: 0,
    shareCount: 0,
    showEmojiPicker: false,
    newComment: '',
  },
  reducers: {
    setShowEmojiPicker(state, action) {
      state.showEmojiPicker = action.payload;
    },
    setNewComment(state, action) {
      state.newComment = action.payload;
    },
    incrementDownload(state) {
      state.downloadCount += 1;
    },
    incrementShare(state) {
      state.shareCount += 1;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Comments Reducer
      .addCase(fetchComments.pending, (state) => {
        state.commentLoading = true;
      })
      .addCase(fetchComments.fulfilled, (state, action) => {
        state.comments = action.payload;
        state.commentLoading = false;
      })
      .addCase(fetchComments.rejected, (state, action) => {
        state.commentLoading = false;
        state.error = action.payload;
      })
      
      // Submit Comment Reducer
      .addCase(submitComment.pending, (state) => {
        state.commentSubmitLoading = true;
      })
      .addCase(submitComment.fulfilled, (state) => {
        state.newComment = ''; // Clear comment input
        state.commentSubmitLoading = false;
      })
      .addCase(submitComment.rejected, (state, action) => {
        state.commentSubmitLoading = false;
        state.error = action.payload;
      })
      
      // Toggle Video Like Reducer
      .addCase(toggleLike.pending, (state) => {
        state.likeLoading = true;
  
      })
      .addCase(toggleLike.fulfilled, (state) => {
        state.liked = !state.liked;
        state.likeCount += state.liked ? 1 : -1; // Adjust like count
        state.likeLoading = false;
      })
      .addCase(toggleLike.rejected, (state, action) => {
        state.likeLoading = false;
        state.error = action.payload;
      })

      // Toggle Comment Like Reducer
      .addCase(toggleCommentLike.pending, (state) => {
        state.commentLikeLoading = true;
      })
      .addCase(toggleCommentLike.fulfilled, (state, action) => {
        const { commentId, liked } = action.payload;
        const comment = state.comments.find((comment) => comment.id === commentId);
        if (comment) {
          comment.liked = liked;
          comment.like = liked ? JSON.parse((comment.like+1)) : JSON.parse((comment.like-1)); // Increment or decrement like count
        }
        // if (comment) {
        //   comment.liked = liked;
        //   comment.like += liked ? 1 : -1; // Increment or decrement like count
        // }
        state.commentLikeLoading = false;
      })
      .addCase(toggleCommentLike.rejected, (state, action) => {
        state.commentLikeLoading = false;
        state.error = action.payload;
      })

      // Download Video Reducer
      .addCase(downloadVideo.pending, (state) => {
        state.downloadLoading = true;
      })
      .addCase(downloadVideo.fulfilled, (state) => {
        state.downloadLoading = false;
      })
      .addCase(downloadVideo.rejected, (state, action) => {
        state.downloadLoading = false;
        state.error = action.payload;
      })

      // Share Video Reducer
      .addCase(shareVideo.pending, (state) => {
        state.shareLoading = true;
      })
      .addCase(shareVideo.fulfilled, (state) => {
        state.shareLoading = false;
      })
      .addCase(shareVideo.rejected, (state, action) => {
        state.shareLoading = false;
        state.error = action.payload;
      });
  },
});

// Export the actions and the reducer
export const { setShowEmojiPicker, setNewComment, incrementDownload, incrementShare } = newUserVideoModalSlice.actions;
export default newUserVideoModalSlice.reducer;
