// profileSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Get token from localStorage
const getToken = () => localStorage.getItem('token');

// Fetch profile data
export const fetchProfile = createAsyncThunk('profile/fetchProfile', async (userId, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL_AUTH}/user/profile/${userId}`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    });
    return response.data.data;
  } catch (error) {
    return rejectWithValue('Error fetching profile');
  }
});

// Fetch regular videos
export const fetchVideos = createAsyncThunk('profile/fetchVideos', async (userId, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL_VIDEO}/user/videos/${userId}`, {
      params: { isDuet: 0 }, // Non-duet videos
      headers: { Authorization: `Bearer ${getToken()}` },
    });
    return response.data.data.Items || [];
  } catch (error) {
    return rejectWithValue('Error fetching videos');
  }
});

// Fetch duet videos
export const fetchDuetVideos = createAsyncThunk('profile/fetchDuetVideos', async (userId, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL_VIDEO}/user/videos/${userId}`, {
      params: { isDuet: 1 }, // Duet videos
      headers: { Authorization: `Bearer ${getToken()}` },
    });
    return response.data.data.Items || [];
  } catch (error) {
    return rejectWithValue('Error fetching duet videos');
  }
});

// Follow or unfollow user
export const followOrUnfollow = createAsyncThunk('profile/followOrUnfollow', async (userId, { getState, rejectWithValue }) => {
  const { profile } = getState().profile;
  const apiUrl = `${process.env.REACT_APP_BASE_URL_AUTH}/${profile?.isFollowing ? 'unfollow' : 'follow'}`;
  try {
    const response = await axios({
      method: profile?.isFollowing ? 'delete' : 'post',
      url: apiUrl,
      data: { userId: profile.id },
      headers: { Authorization: `Bearer ${getToken()}` },
    });

    if (response.status === 200 || response.status === 201) {
      return {
        isFollowing: !profile.isFollowing,
        totalFollowers: profile.isFollowing ? profile.totalFollowers - 1 : profile.totalFollowers + 1,
      };
    } else {
      throw new Error('Failed to follow/unfollow');
    }
  } catch (error) {
    return rejectWithValue('Error following/unfollowing user');
  }
});

// Profile slice
const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    profile: null,
    videos: [],
    duetVideos: [],
    isLoading: true,
    noUser: false,
    activeTab: 'videos',
    showFollowersBox: false,
    showFollowingBox: false,
    showUnfollowModal: false,
    showBlockModal: false,
    selectedVideoIndex: null,
    buttonLoading: false,
  },
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setShowFollowersBox: (state, action) => {
      state.showFollowersBox = action.payload;
    },
    setShowFollowingBox: (state, action) => {
      state.showFollowingBox = action.payload;
    },
    setSelectedVideoIndex: (state, action) => {
      state.selectedVideoIndex = action.payload;
    },
    setShowUnfollowModal: (state, action) => {
      state.showUnfollowModal = action.payload;
    },
    setShowBlockModal: (state, action) => {
      state.showBlockModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Profile
      .addCase(fetchProfile.pending, (state) => {
        state.isLoading = true;
        state.noUser = false;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.profile = action.payload;
      })
      .addCase(fetchProfile.rejected, (state) => {
        state.isLoading = false;
        state.noUser = true;
      })
      // Fetch Videos
      .addCase(fetchVideos.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchVideos.fulfilled, (state, action) => {
        state.videos = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchVideos.rejected, (state) => {
        state.isLoading = false;
      })
      // Fetch Duet Videos
      .addCase(fetchDuetVideos.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDuetVideos.fulfilled, (state, action) => {
        state.duetVideos = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchDuetVideos.rejected, (state) => {
        state.isLoading = false;
      })
      // Follow/Unfollow User
      .addCase(followOrUnfollow.pending, (state) => {
        state.buttonLoading = true;
      })
      .addCase(followOrUnfollow.fulfilled, (state, action) => {
        state.profile.isFollowing = action.payload.isFollowing;
        state.profile.totalFollowers = action.payload.totalFollowers;
        state.buttonLoading = false;
      })
      .addCase(followOrUnfollow.rejected, (state) => {
        state.buttonLoading = false;
      });
  },
});

export const {
  setActiveTab,
  setShowFollowersBox,
  setShowFollowingBox,
  setSelectedVideoIndex,
  setShowUnfollowModal,
  setShowBlockModal,
} = profileSlice.actions;

export default profileSlice.reducer;
